import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import ContentWithImage from "../components/ContentWithImage";
import ContentWithSlider from "../components/ContentWithSlider";
import Testimonials from "../components/Testimonials";
import SliderSection from "../components/SliderSection";

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      content
      pageSections {
        sections {
          __typename
          ...HeroSection
          ...WpContent
          ...WpContentSlider
          ...SliderSection
          ...Testimonials
        }
      }
      pageLinks {
        ...WpPage_PagelinksFragment
      }
    }
  }
`;

const PageTemplate = ({ data }) => {
  const page = data.wpPage;
  const sections = data.wpPage.pageSections.sections;

  return (
    <Layout title={page.title} page={page}>
      <main>
        {sections &&
          sections.map((section, index) => {
            const typeName = section.__typename;

            switch (typeName) {
              case "WpPage_Pagesections_Sections_Hero":
                return <Hero key={index} {...section} />;
              case "WpPage_Pagesections_Sections_ContentWithImage":
                return <ContentWithImage key={index} {...section} />;
              case "WpPage_Pagesections_Sections_Slider":
                return <SliderSection key={index} {...section} />;
              case "WpPage_Pagesections_Sections_Testimonials":
                return <Testimonials key={index} {...section} />;
              case "WpPage_Pagesections_Sections_ContentWithSlider":
                return <ContentWithSlider key={index} {...section} />;
              default:
                return <p>No section available</p>;
            }
          })}
      </main>
    </Layout>
  );
};

export default PageTemplate;
